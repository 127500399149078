
    .course-content {
        width: 100%;
        height: 100%;
        position: relative;

        .course-head {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 80px;
            background: #409EFF;
            color: #ffffff;
            font-size: 18px;
            border-bottom-left-radius: 60% 50%;
            border-bottom-right-radius: 60% 50%;
        }

        .form-content {
            height: calc(100% - 20px);
            z-index: 0;
            width: calc(100% - 160px);
            margin: 0 auto;
            background: #ffffff;

            .main-box {
                width: 100%;
                height: calc(100% - 80px);
                padding-top: 80px;

                .subject-ruleForm {
                    height: 100%;

                    .subject-content {
                        flex: 1;
                        height: 100%;

                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                            height: calc(100% - 60px);
                        }

                        ::v-deep .el-scrollbar__view {
                            padding: 0 100px;
                            min-width: 1300px;
                            height: 100%;
                        }

                        .relation-course {
                            display: flex;

                            ::v-deep .el-form-item__label {
                                width: 300px;
                            }
                        }

                        .fish-title {
                            display: flex;

                            ::v-deep .el-form-item__label {
                                width: 90px;
                            }
                        }

                        .course-list {
                            ::v-deep .el-checkbox-group {
                                display: flex;
                                flex-wrap: wrap;
                            }

                            ::v-deep .el-checkbox-button {
                                width: calc(20% - 20px);
                                margin-bottom: 18px;
                                border-radius: 20px;
                            }

                            ::v-deep .el-checkbox-button__inner {
                                border: unset;
                                border-radius: 20px;
                                border: 1px solid #DCDFE6;
                                max-width: 250px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            ::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
                                box-shadow: 0px 0 0 0 #8cc5ff;
                            }

                        }

                        .add-btn {
                            position: relative;

                            .notice-title {
                                font-size: 14px;
                                color: #EB1515;
                                position: absolute;
                                left: 80px;
                                top: 10px;
                            }

                            .btn-box {
                                width: 100px;
                                height: 30px;
                                background: #409EFF;
                                border: 1px solid #DCECFE;
                                border-radius: 4px;
                                color: #ffffff;
                                text-align: center;
                                line-height: 2;
                                cursor: pointer;
                                position: absolute;
                                right: 0;
                                top: 0;
                            }
                        }

                        .icon-btn {
                            margin-left: 10px;
                            font-size: 14px;
                            cursor: pointer;
                        }

                        .fishTable {
                            .el-button--text {
                                font-size: 14px;
                            }
                        }

                        .file-name-box {
                            width: 150px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .footer-box {
                            display: flex;
                            justify-content: center;

                            .el-button {
                                margin-right: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
